import React from "react";
import cs from "./scss/Lang.module.scss";
import { Langs } from "../../langs";

const Lang = (props) => {
  return (
    <div className={cs.lang}>
      <button
        onClick={() => {
          props.setLang(Langs.EN);
        }}
        className={props.lang === Langs.EN ? cs.lang__active : ""}
      >
        <span>En</span>
      </button>
      <button
        onClick={() => {
          props.setLang(Langs.RU);
        }}
        className={props.lang === Langs.RU ? cs.lang__active : ""}
      >
        <span>Rus</span>
      </button>
      <button
        onClick={() => {
          props.setLang(Langs.CH);
        }}
        className={props.lang === Langs.CH ? cs.lang__active : ""}
      >
        <span>Ch</span>
      </button>
    </div>
  );
};

export default Lang;
