import React from "react";
import Phone from "../UI/Phone";
import cs from "./scss/Footer.module.scss";
import { NavLink } from "react-router-dom";
// import Lang from '../UI/Lang'

import logo from "../../assets/logo.png";
import { translate } from "../../langs";

const Group = (props) => {
  return (
    <div className={cs.group}>
      <div className={cs.contacts}>
        <ul>
          <li>
            <strong>{translate("Контакты", props.lang)}</strong>
          </li>
          <li>{translate("г. Астана", props.lang)}</li>
          <li>{translate("Республика Казахстан", props.lang)}</li>
          <li>{translate("Резидент МФЦА", props.lang)}</li>
          <li>info@volkovgroup.kz</li>
        </ul>
      </div>

      <div className={cs.links}>
        <ul>
          <li>
            <strong>{translate("Ссылки", props.lang)}</strong>
          </li>
          <li>
            <NavLink to="/main"> {translate("Главное", props.lang)} </NavLink>
          </li>
          <li>
            <NavLink to="/services">{translate("Сервисы", props.lang)}</NavLink>
          </li>
          <li>
            <NavLink to="/about">{translate("О компании", props.lang)}</NavLink>
          </li>
          <li>
            <NavLink to="/contacts">
              {translate("Контакты", props.lang)}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

const Footer = (props) => {
  return (
    <div className={cs.wrapper}>
      <div className={cs.logo}>
        <img src={logo} alt="" />
      </div>
      <div className={cs.group}>
        <Group {...props} />
        <Phone {...props} />
      </div>
    </div>
  );
};

export default Footer;
