import React from "react";
import cs from "./scss/MainPage.module.scss";
import ArrowButton from "../../components/UI/ArrowButton";
import Feedback from "../../components/UI/Feedback";
import { NavLink } from "react-router-dom";

import Slider from "./Slider";
import { translate } from "../../langs";

const ServicesNav = (props) => {
  return (
    <div className={cs.services__nav}>
      <h1>{translate("Наши Сервисы", props.lang)}</h1>
      <ul>
        <li>
          <NavLink to="/services/1">
            <span>{translate("Регистрация в МФЦА", props.lang)}</span>
          </NavLink>
        </li>
        <li>
          <NavLink to="/services/2">
            <span>{translate("Налоговая оптимизация", props.lang)}</span>
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

const ServicesAbout = (props) => {
  return (
    <div className={cs.services__about}>
      <div className={cs.services__content}>
        <div className={cs.count}>
          <h1>8</h1>
          <p>
            {translate(
              "видов лицензий были получены нашими клиентами в МФЦА",
              props.lang
            )}
          </p>
        </div>

        <div className={cs.services__content_main}>
          <h1>
            {translate(
              "Мы оказываем услуги по регистрации компаний в МФЦА и налоговой оптимизации с момента открытия МФЦА с 2018 года",
              props.lang
            )}
          </h1>
          <p>
            {translate(
              "Мы специализируемся на регистрации инвестиционных и финансовых компаний в МФЦА, а также налоговой оптимизации бизнеса с использованием международных и национальных механизмов.С момента открытия МФЦА нами были успешно зарегистрированы ряд крупных национальных и международных компаний и получены лицензии на осуществление банковской деятельности, биржевой деятельности, операций с криптовалютой, венчурного финансирования.",
              props.lang
            )}
          </p>
          <ArrowButton
            {...props}
            nav={true}
            label={translate("Узнать подробнее", props.lang)}
            link="/services/1"
          />
        </div>
      </div>
    </div>
  );
};

const MainPage = (props) => {
  return (
    <div className={cs.mainpageWrapper}>
      <Slider {...props} />

      <div className={cs.services}>
        <ServicesNav {...props} />
        <ServicesAbout {...props} />
        <Feedback {...props} />
      </div>
    </div>
  );
};

export default MainPage;
