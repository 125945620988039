import { createSlice, configureStore } from "@reduxjs/toolkit";

// export enum Langs {
//   RU = "RU",
//   EN = "EN",
//   CH = "CH",
// }
export const Langs = {
  RU: "RU",
  EN: "EN",
  CH: "CH",
};

const langSlice = createSlice({
  name: "lang",
  initialState: {
    lang: Langs.RU,
  },
  reducers: {
    changeLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const store = configureStore({
  reducer: langSlice.reducer,
});
