import React from "react";
// import clsx from 'clsx';
// import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Icon from "@mui/material/Icon";

import Lang from "../UI/Lang";
import { NavLink } from "react-router-dom";

import cs from "./scss/MyDrawer.module.scss";
import { translate } from "../../langs";

// const useStyles = makeStyles({
//   list: {
//     width: 250,
//   },
//   fullList: {
//     width: "auto",
//   },
// });

export default function MyDrawer(props) {
  // const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div className={cs.wrapper}>
      <Button className={cs.button} onClick={toggleDrawer("left", true)}>
        {<Icon style={{ fontSize: 30 }}>menu</Icon>}
      </Button>
      <Drawer
        // classes={{
        //   root: classes.root,
        // }}
        anchor={"left"}
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
      >
        <div
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <List>
            <ListItem>
              <div className={cs.lang_drawer}>
                <Lang {...props} />
              </div>
            </ListItem>
            <br />
            <br />
            <ListItem>
              <NavLink to="/main" activeClassName="drawer_active">
                <span>{translate("Главное", props.lang)}</span>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/services" activeClassName="drawer_active">
                <span>{translate("Сервисы", props.lang)}</span>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/about" activeClassName="drawer_active">
                <span>{translate("О компании", props.lang)}</span>
              </NavLink>
            </ListItem>
            <ListItem>
              <NavLink to="/contacts" activeClassName="drawer_active">
                <span>{translate("Контакты", props.lang)}</span>
              </NavLink>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
}
