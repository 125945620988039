import React, { useState, useRef } from "react";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import ArrowButton from "./ArrowButton";
import cs from "./scss/Feedback.module.scss";
import { translate } from "../../langs";

const Feedback = (props) => {
  const [state, setState] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const refs = {
    name: useRef(),
    phone: useRef(),
    email: useRef(),
    message: useRef(),
  };

  function submitForm(e) {
    e.preventDefault();

    if (!state.name) {
      refs.name.current.focus();
      NotificationManager.error(translate("Заполните поле 'Имя'", props.lang));
      return;
    } else if (!state.phone) {
      refs.phone.current.focus();
      NotificationManager.error(
        translate("Заполните поле 'Телефон'", props.lang)
      );
      return;
    } else if (!state.email) {
      refs.email.current.focus();
      NotificationManager.error(
        translate("Заполните поле 'E-mail'", props.lang)
      );
      return;
    } else if (!state.message) {
      refs.message.current.focus();
      NotificationManager.error(
        translate("Заполните поле 'Сообщение'", props.lang)
      );
      return;
    }

    const token = "1904121618:AAHawYQAYfmvxab410KfD0HiSdiCyyRSmKE";
    const chat_id = "889332493";
    // const chat_id = "861028145";
    // chat_id pavel 889332493
    // chat id zhassbala 861028145
    const content = `Имя: ${state.name} \nТелефон: ${state.phone} \nEmail: ${state.email} \nСообщение: ${state.message}`;
    const url = `https://api.telegram.org/bot${token}/sendMessage`;

    const payload = {
      chat_id: chat_id,
      text: content,
    };

    // https://api.telegram.org/bot1904121618:AAHawYQAYfmvxab410KfD0HiSdiCyyRSmKE/getUpdates

    axios.post(url, payload).then((res) => {
      document.getElementById("feedbackForm").reset();
      NotificationManager.success(
        translate(
          "Ваше сообщение отправлено. Благодарим Вас за обращение!",
          props.lang
        ),
        translate("Спасибо за обращение!", props.lang)
      );
      setState({
        name: "",
        phone: "",
        email: "",
        message: "",
      });
    });
  }

  return (
    <div className={cs.formWrapper}>
      <NotificationContainer />
      <h1>{translate("Обратная связь", props.lang)}</h1>
      <form id="feedbackForm">
        <div className={cs.form_grid}>
          <div className={cs.col1}>
            <input
              onInput={(e) => setState({ ...state, name: e.target.value })}
              required
              name="name"
              id="name"
              type="text"
              placeholder={translate("Ваше Имя", props.lang)}
              ref={refs.name}
            />
            <input
              onInput={(e) => setState({ ...state, phone: e.target.value })}
              required
              name="phone"
              id="phone"
              type="tel"
              placeholder={translate("Телефон", props.lang)}
              ref={refs.phone}
            />
            <input
              onInput={(e) => setState({ ...state, email: e.target.value })}
              required
              name="email"
              id="email"
              type="email"
              placeholder={translate("E-Mail", props.lang)}
              ref={refs.email}
            />
          </div>

          <div className={cs.col2}>
            <input
              onInput={(e) => setState({ ...state, message: e.target.value })}
              required
              name="message"
              id="message"
              type="text"
              placeholder={translate("Ваше Сообщение", props.lang)}
              ref={refs.message}
            />
            <button type="submit" onClick={submitForm}>
              <ArrowButton
                label={translate("Отправить", props.lang)}
                link="/main"
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Feedback;
