import React from "react";

import cs from "./scss/AboutCompany.module.scss";
import { Langs, translate } from "../../langs";

const Content = (props) => {
  return (
    <div className={cs.content}>
      <div className={cs.contentSingle}>
        <h1>
          {translate(
            "Мы являемся международной консалтинговой компанией, специализирующейся на регистрации компаний в МФЦА и международной налоговой оптимизации",
            props.lang
          )}
        </h1>
        <p>
          {translate(
            "Основными приоритетами нашей деятельности являются: индивидуальный подход, конфиденциальность и комплексный анализ бизнеса наших клиентов для предложения самых подходящих решений. Основными интересами наших клиентов является обеспечение безопасности их бизнеса и создание им условий для сокращения расходов. Мы помогаем нашим клиентам достичь указанных целей и предлагаем им новые направления развития их бизнеса. Нашими клиентами являются представители среднего и крупного национального и международного бизнеса.",
            props.lang
          )}
        </p>
      </div>
    </div>
  );
};

const Principle = (props) => {
  return (
    <div className={cs.principleItem}>
      <div className={`${cs.number} ${props.lang === Langs.CH ? cs.ch : ""}`}>
        <h1>{props.id}</h1>
        <span>{translate("Принцип", props.lang)}</span>
      </div>

      <div className={cs.text}>
        <p> {props.text} </p>
      </div>
    </div>
  );
};

const Principles = (props) => {
  return (
    <div className={cs.principles}>
      <Principle
        {...props}
        id="1"
        text={translate(
          "Исключительно качество оказываемых нами услуг и направленность на достижение конечного результата формируют ценность для наших клиентов.",
          props.lang
        )}
      />
      <Principle
        {...props}
        id="2"
        text={translate(
          "Предлагаемые компанией решения всегда основаны на комплексном анализе бизнес-процессов наших клиентов и направлены на достижение конечного положительного результата.",
          props.lang
        )}
      />
      <Principle
        {...props}
        id="3"
        text={translate(
          "Конфиденциальность и обеспечение приватности наших клиентов являются основными приоритетами нашей компании",
          props.lang
        )}
      />
      <Principle
        {...props}
        id="4"
        text={translate(
          "Мы гордимся тем, что для каждого клиента нами создается индивидуальный продукт, призванный гарантировать достижение требуемого результата.",
          props.lang
        )}
      />
    </div>
  );
};

const AboutCompany = (props) => {
  return (
    <div className={cs.wrapper}>
      <h1 className={cs.about}>{translate("О компании", props.lang)}</h1>
      <Content {...props} />
      <Principles {...props} />
    </div>
  );
};

export default AboutCompany;
