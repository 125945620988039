import React from "react";
import { BrowserRouter, Route, NavLink } from "react-router-dom";
import ArrowButton from "../../components/UI/ArrowButton";
import { translate } from "../../langs";

import cs from "./scss/Services.module.scss";

const LabelServices1 = (props) => {
  return (
    <div className={cs.label}>
      <p>
        {translate(
          "Регистрация в Международном Финансовом Центре Астаны",
          props.lang
        )}
      </p>
    </div>
  );
};

const LabelServices2 = (props) => {
  return (
    <div className={cs.label}>
      <p>{translate("Налоговая оптимизация", props.lang)}</p>
    </div>
  );
};

// const LabelServices3 = (props) => {
//   return (
//     <div className={cs.label}>
//       {props.lang === Langs.RU ? (
//         <p>
//           Построение культуры принятия решений на основе данных (Data-Driven
//           culture) <br />
//           <br /> Управление информационной безопасностью (Cyber Security)
//         </p>
//       ) : (
//         <p>
//           Introduction of data-driven culture <br />
//           <br /> CYBER SECURITY
//         </p>
//       )}
//     </div>
//   );
// };

// const LabelServices4 = (props) => {
//   return (
//     <div className={cs.label}>
//       {props.lang === Langs.RU ? (
//         <p>
//           Налоговая практика
//           <br />
//           <br />
//           Разрешение споров в сфере государственных закупок
//           <br />
//           <br />
//           Практика разрешения споров <br />
//           <br />
//           Практика финансового оздоровления деятельности компании
//         </p>
//       ) : (
//         <p>
//           Tax practice <br />
//           <br />
//           State procurements disputes resolution <br />
//           <br />
//           Dispute resolution <br />
//           <br />
//           Financial refinement of Company activities
//         </p>
//       )}
//     </div>
//   );
// };

// const LabelServices5 = (props) => {
//   return (
//     <div className={cs.label}>
//       {props.lang === Langs.RU ? (
//         <p>
//           <strong>Категория А:</strong> Инвестиционные проекты до 13,3 миллионов
//           долларов США
//           <br />
//           <br />
//           <strong>Категория В:</strong> Инвестиционно-приоритетные проекты от
//           13,3 миллионов долларов США по созданию новых производств; от 33,3
//           миллионов долларов США по расширению действующих производств.
//         </p>
//       ) : (
//         <p>
//           <strong>A Category:</strong> Investment projects with NPV limited to
//           USD 13,3 million
//           <br />
//           <br />
//           <strong>B Category:</strong> Investment projects with NPV exceeding
//           USD 13,3 million for newly registered enterprises; exceeding USD 33,3
//           million to scale current operations
//         </p>
//       )}
//     </div>
//   );
// };

const Content1 = (props) => {
  return (
    <div className={`${cs.content} ${cs.withBG}`}>
      <div className={cs.contentDouble}>
        <h1>
          {translate(
            "Регистрация в Международном Финансовом Центре Астаны",
            props.lang
          )}
        </h1>
        <div className={cs.leftcol}>
          <p>
            {translate(
              "Международный финансовый центр Астаны был создан для целей увеличения привлекательности Республики Казахстан для международных инвесторов. МФЦА функционирует в соответствии спринципами общего права Англии, имеет собственное законодательство, собственные органы управления и контроля, свой суд и арбитраж.",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Основными преимуществами МФЦА являются: освобождение от уплаты корпоративного подоходного налога, индивидуального подоходного налога сроком на 50 лет; отсутствие валютного контроля; отсутствие визового режима. Компания-резидент МФЦА получает статус международной, что способствует защите бизнеса, увеличению доверия со стороны иностранных инвесторов и национальных компаний, обеспечивает поддержку государства, предоставляет доступ к капиталу, поскольку в МФЦА находятся крупные институциональные инвесторы, а также визовые преференции для иностранных сотрудников.",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "На платформе МФЦА функционирует собственная Биржа, что также предоставляет компаниям доступ к рынку капитала и возможность привлечения денежных средств через механизм публичного размещения акций (IPO) или облигаций.",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Основными интересами клиентов при регистрации компаний в МФЦА являются обеспечение защиты бизнеса, оптимизация налогообложения, применение английского права и доступ к инвестициям.",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Специалисты нашей компании помогают регистрировать компании в МФЦА и предоставляют поддержку для получения необходимой лицензии и дальнейшей налоговой оптимизации.",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Наши клиенты получили 8 разных видов лицензий в МФЦА.",
              props.lang
            )}
          </p>
        </div>

        <div className={cs.rightcol}>
          <span className={cs.exp}>
            {translate("Наши экспертизы:", props.lang)}
          </span>
          <p>
            {translate(
              "Выбор организационно-правовой формы для целей регистрации компании в МФЦА;",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Составление и подача комплекта необходимых для регистрации документов;",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Написание бизнес-плана, соответствующего Стандартам МФЦА;",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Налоговая оптимизация деятельности компании;",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Предоставление разъяснений по вопросам законодательства МФЦА;",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Подготовка руководителя компании к собеседованию с представителями МФЦА;",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Получение соответствующей лицензии на право занятия определенной деятельностью в МФЦА;",
              props.lang
            )}
            <br />
            <br />
            {translate(
              "Подготовка и последующее сопровождение компании при проведении публичного размещения акций (IPO) или облигаций.",
              props.lang
            )}
          </p>
          <br />
          <br />
          <ArrowButton
            label={translate("Назначить встречу", props.lang)}
            link="/contacts"
          />
        </div>
      </div>
    </div>
  );
};

const Content2 = (props) => {
  return (
    <div className={`${cs.content} ${cs.withBG}`}>
      <div className={cs.contentSingle}>
        <h1>{translate("Налоговая оптимизация", props.lang)}</h1>
        <p>
          {translate(
            "Партнером нашей компании является лондонская консалтинговая фирма IFS Consultants, специализирующаяся на международном налоговом планировании более 50 лет. Совместно мы предлагаем юридическим и физическим лицам налоговые маневры для оптимизации бизнеса с использованием национальных и международных структур, а также защиты бизнеса и благосостояния наших клиентов.",
            props.lang
          )}
          <br />
          <br />
          {translate(
            "Наша деятельность включает в себя: регистрацию юридических лиц в любой международной юрисдикции, создание трастов и холдинговых компаний, перераспределение налоговой нагрузки у источника дохода, создание трансграничных налоговых механизмов для целей уменьшения налоговой базы клиентов и применения преимуществ Соглашений об избежании двойного налогообложения",
            props.lang
          )}
          <br />
          <br />
          {translate(
            "Отдельная часть наших услуг охватывает защиту интересов частных клиентов – физических лиц, включая оптимизацию налогообложения, создание налоговых структур для уменьшения налогового бремени при приобретении имущества в Европе, обеспечение безопасной передачи имущества наследникам и сохранение семейного благосостояния, обеспечение безопасности капиталов бенефициаров – физических лиц, представление интересов клиентов по спорам с налоговыми органами.",
            props.lang
          )}
          <br />
          <br />
          {translate(
            "Нашими ключевыми клиентами являются юридические и физические лица – резиденты стран СНГ. Обширный опыт и понимание специфики рынка СНГ позволяет нам обеспечивать безопасность капиталов для клиентов и отсутствие претензий со стороны налоговых органов.",
            props.lang
          )}
        </p>
        <ArrowButton
          label={translate("Назначить встречу", props.lang)}
          link="/contacts"
        />
      </div>
    </div>
  );
};

// const Content3 = (props) => {
//   return (
//     <>
//       <div className={`${cs.content} ${cs.withBG}`}>
//         <div className={cs.contentDouble}>
//           {props.lang === Langs.RU ? (
//             <h1 className={cs.single}>
//               Построение культуры принятия решений на основе данных (Data-Driven
//               culture)
//             </h1>
//           ) : (
//             <h1 className={cs.single}>Introduction of data-driven culture</h1>
//           )}

//           <div className={cs.leftcol}>
//             {props.lang === Langs.RU ? (
//               <p>
//                 На сегодняшний день одними из главных задач, стоящих перед
//                 менеджментом и собственниками бизнеса, являются анализ
//                 операционных данных и принятие по результатам такого анализа
//                 необходимых управленческих решений.
//                 <br />
//                 <br />
//                 В процессе операционной деятельности компании генерируют большой
//                 массив данных, эффективный анализ которых позволяет
//                 оптимизировать и усовершенствовать внедряемые в компании
//                 бизнес-процессы, а также выявить перспективные направления для
//                 дальнейшего развития.
//                 <br />
//                 <br />
//                 Одним из наиболее эффективных подходов к принятию управленческих
//                 решений является data-driven подход, сущность которого состоит в
//                 том, что принимаемые решения должны быть основаны исключительно
//                 на проверенных операционных данных компании.
//                 <br />
//                 <br />
//                 Наша команда на протяжении нескольких лет успешно применяет на
//                 практике данный подход в сотрудничестве с крупными банками, а
//                 также лидерами различных отраслей бизнеса.
//               </p>
//             ) : (
//               <p>
//                 Nowadays one of the main challenges which management and owners
//                 of business encounters are the process of operational data
//                 analysis and making sound management decisions based on the
//                 results of such analysis.
//                 <br />
//                 <br />
//                 In the process of operations, a company generates large amount
//                 of data and effective analysis of this data enables optimization
//                 and implementation of improvements in a company business
//                 processes and identification of beneficial areas for further
//                 development.
//                 <br />
//                 <br />
//                 One of the most effective approaches to make management
//                 decisions is the data-driven approach implying that decisions
//                 taken must be solely based on a verified operating data of the
//                 company.
//                 <br />
//                 <br />
//                 Our team throughout several successful years used this approach
//                 in cooperation with major banks and leaders of various business
//               </p>
//             )}
//             <ArrowButton
//               label={
//                 props.lang === Langs.RU
//                   ? "Назначить встречу"
//                   : "Schedule appointment"
//               }
//               link="/contacts"
//             />
//           </div>

//           <div className={cs.rightcol}>
//             {props.lang === Langs.RU ? (
//               <>
//                 <span className={cs.exp}>Наши экспертизы:</span>
//                 <p>
//                   <strong>Управление данными: </strong>
//                   Интеграция и сбор данных. Очистка, валидация, стандартизация.
//                   Обогащение, анализ. Разработка корпоративной политики
//                   управления данными. Проектирование и разработка хранилищ
//                   корпоративных данных (DWH), в которых данные представлены в
//                   структурированном, готовом к анализу состоянии.
//                   <br />
//                   <br />
//                   <strong>
//                     Объективная оценка текущей деятельности компании:
//                   </strong>
//                   <br />
//                   Проектирование и создание аналитических отчетов с помощью
//                   современных и наглядных средств визуализации для контроля и
//                   мониторинга текущей деятельности компании и ее сотрудников в
//                   режиме real-time. <br />
//                   Анализ поведения клиентов с целью построения наиболее
//                   эффективного взаимодействия в контексте omni-channel.
//                   <br />
//                   <br />
//                   <strong>Предиктивная аналитика деятельности компании:</strong>
//                   <br />
//                   Рекомендации по выработке продукта на основе анализа данных о
//                   предпочтениях пользователей. Предсказание поведения клиентов.
//                   <br />
//                   Кредитный скоринг.
//                   <br />
//                   Решение задач оптимизации в контактных центрах, складах,
//                   логистике, банках и иных отраслях бизнеса. Предсказание
//                   внешних факторов, влияющих на бизнес.
//                   <br />
//                   Внедрение системы превентивного анализа, позволяющей
//                   установить предполагаемые слабые места до момента их
//                   обнаружения. Анализ перспектив дальнейшего развития бизнеса.
//                   <br />
//                 </p>
//               </>
//             ) : (
//               <p>
//                 <strong>Data management: </strong> <br />
//                 Integration and data collection. Cleaning, validation,
//                 standardization of the data. Enrichment, data analysis.
//                 Development of the data management corporate policy. Design and
//                 development of corporate data warehouses (DWH) where data is
//                 structured and prepared for further analysis.
//                 <br />
//                 <br />
//                 <strong>
//                   Objective assessment of the Company operational activities:
//                 </strong>
//                 <br />
//                 Design and creation of analytical reports by using the
//                 contemporary visualization tools to control and monitor online
//                 the current activities of the company and its employees.
//                 Analysis of clients’ behavior in order to establish the most
//                 effective interaction as part of omni-channel.
//                 <br />
//                 <br />
//                 <strong>
//                   Predictive analysis of the Company’s activities:
//                 </strong>
//                 <br />
//                 Guidance for the development of product based on data analysis
//                 of user preferences.
//                 <br />
//                 Prediction of customer behavior
//                 <br />
//                 Credit scoring
//                 <br />
//                 Solution of optimization problems in contact centers,
//                 warehouses, logistics, banks and other business sectors.
//                 <br />
//                 Prediction of external factors affecting business.
//                 <br />
//                 Introduction of a preventive system of analysis enabling
//                 identification of alleged weaknesses prior to their detection
//                 <br />
//                 Analysis of prospects of further business development.
//               </p>
//             )}
//           </div>
//         </div>
//       </div>

//       <div className={`${cs.content} ${cs.withBG}`}>
//         {props.lang === Langs.RU ? (
//           <div className={cs.contentDouble}>
//             <h1 className={cs.single}>
//               Управление информационной безопасностью (Cyber Security)
//             </h1>

//             <div className={cs.leftcol}>
//               <p>
//                 Информационная безопасность представляет собой комплекс мер по
//                 защите информации и компании в целом от преднамеренных или
//                 случайных действий, наносящих ущерб деятельности компании.
//                 <br />
//                 <br />
//                 Обеспечение информационной безопасности должно быть направлено
//                 прежде всего на предотвращение причин уязвимостей, а не только
//                 на ликвидацию их последствий.
//                 <br />
//                 <br />
//                 Именно принятие предупредительных мер по обеспечению
//                 конфиденциальности, целостности, а также доступности информации
//                 и является наиболее правильным подходом в создании системы
//                 информационной безопасности.
//                 <br />
//                 <br />
//                 Многолетняя практика анализа защищенности крупных порталов,
//                 банковских и производственных систем показывает, что
//                 защищённость «на бумаге» оборачивается крайней беззащитностью
//                 бизнеса перед реальными угрозами. Формальный подход к обороне
//                 позволяет злоумышленникам месяцами контролировать самые
//                 критичные системы, подвергая риску конфиденциальность данных и
//                 сохранность счетов.
//                 <br />
//                 <br />
//                 Мы тщательно исследуем практическую защищённость
//                 бизнес-процессов. Хорошо понимая актуальные технологии
//                 киберпреступников, мы видим слабости систем наших клиентов
//                 глазами киберпреступников.
//                 <br />
//                 По результатам проведенного аудита нами выдается заключение о
//                 наличии уязвимостей и перечень конкретных рекомендаций для
//                 подготовки к отражению реальных угроз.
//               </p>
//               <ArrowButton
//                 label={
//                   props.lang === Langs.RU
//                     ? "Назначить встречу"
//                     : "Schedule appointment"
//                 }
//                 link="/contacts"
//               />
//             </div>

//             <div className={cs.rightcol}>
//               <span className={cs.exp}>Наши экспертизы:</span>
//               <p>
//                 <strong>Тестирование на защищенность:</strong>
//                 <br />
//                 Методов социальной инженерии — позволяет оценить уровень знаний
//                 и осведомленности сотрудников компании в области
//                 кибербезопасности.
//                 <br />
//                 Веб-приложений — поиск уязвимостей и ошибок, определение
//                 векторов потенциальных атак.
//                 <br />
//                 Объектов инфраструктуры — состоит из анализа защищенности от
//                 угроз проникновения, имея доступ к сети компании как изнутри,
//                 так и извне.
//                 <br />
//                 <br />
//                 <strong>Компьютерная криминалистика:</strong>
//                 <br />
//                 Исследование и анализ вредоносного
//                 <br />
//                 ПО -анализ ВПО, выдвижение рекомендаций по его устранению и
//                 предотвращению подобных инцидентов в будущем.
//                 <br />
//                 Расследование инцидентов кибербезопасности – идентифицируем
//                 способ проникновения в систему, обстоятельства при которых
//                 произошел инцидент и предлагаем рекомендации по устранению
//                 уязвимостей.
//                 <br />
//                 Внедрение системы превентивного анализа, позволяющей установить
//                 предполагаемые слабые места до момента их обнаружения. Анализ
//                 перспектив дальнейшего развития бизнеса.
//                 <br />
//                 <br />
//                 <strong>Обучение:</strong>
//                 <br />
//                 Курсы по анализу вредоносного программного обеспечения.
//               </p>
//             </div>
//           </div>
//         ) : (
//           <div className={cs.contentSingle}>
//             <h1>CYBER SECURITY</h1>
//             <p>
//               Informational security is a complex of measures for information
//               protection and company as a whole from intentional or accidental
//               acts damaging the company’s activity.
//               <br />
//               <br />
//               Information security should be primarily aimed at prevention of
//               the reasons of vulnerabilities and not only at elimination of
//               their consequences. Adoption of precautionary measures to ensure
//               the confidentiality, integrity and availability of information is
//               the most correct approach in developing information security
//               system.
//               <br />
//               <br />
//               Long-term practice of security analysis of large portals, banking
//               and industrial systems shows that formal protection turns out to
//               be the extreme vulnerability of business to real threats. A formal
//               approach to the defense allows intruders to control the most
//               important systems for months compromising confidentiality of the
//               data and security of accounts.
//             </p>
//             <ArrowButton
//               label={
//                 props.lang === Langs.RU
//                   ? "Назначить встречу"
//                   : "Schedule appointment"
//               }
//               link="/contacts"
//             />
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// const Content4_1 = (props) => {
//   return (
//     <div className={`${cs.content} ${cs.withBG}`}>
//       <div className={cs.contentDouble}>
//         {props.lang === Langs.RU ? (
//           <>
//             <h1>Налоговая практика</h1>
//             <div className={cs.leftcol}>
//               <p>
//                 Одной из ключевых компетенций нашей налоговой практики является
//                 судебная защита интересов клиентов по спорам, возникающим в
//                 области международного налогового планирования.
//                 <br />
//                 <br />
//                 Мы успешно представляем в суде интересы крупнейших международных
//                 и национальных компаний по прецедентным спорам, доказывая судьям
//                 законность внедряемых методов налогового планирования и
//                 структурирования.
//               </p>
//               <ArrowButton
//                 label={
//                   props.lang === Langs.RU
//                     ? "Назначить встречу"
//                     : "Schedule appointment"
//                 }
//                 link="/contacts"
//               />
//             </div>
//             <div className={cs.rightcol}>
//               <span className={cs.exp}>Наши экспертизы:</span>
//               <p>
//                 Анализ налоговых рисков;
//                 <br />
//                 <br />
//                 Налоговое структурирование внутригосударственных и международных
//                 сделок;
//                 <br />
//                 <br />
//                 Сопровождение налоговой реструктуризации бизнеса;
//                 <br />
//                 <br />
//                 Организация национальных и международных холдинговых и иных
//                 финансовых структур;
//                 <br />
//                 <br />
//                 Сопровождение клиентов в период проведения налоговых проверок;
//                 <br />
//                 <br />
//                 Сопровождение клиентов на стадии досудебного урегулирования
//                 споров с налоговыми органами;
//                 <br />
//                 <br />
//                 Представление интересов клиентов в судебных органах по налоговым
//                 спорам;
//                 <br />
//                 <br />
//                 Международное налоговое планирование: создание компаний в
//                 международных юрисдикциях для целей дальнейшей оптимизации
//                 налогообложения;
//               </p>
//             </div>
//           </>
//         ) : (
//           <>
//             <h1>Tax practice</h1>
//             <div className={cs.leftcol}>
//               <p>
//                 One of the key competences of our tax practice is judicial
//                 defense of our Clients in international tax planning issues.
//                 <br />
//                 <br />
//                 We are successfully representing best interests of major
//                 international and national Companies in landmark disputes
//                 ensuring legitimacy of our tax planning methods.
//               </p>
//               <ArrowButton
//                 label={
//                   props.lang === Langs.RU
//                     ? "Назначить встречу"
//                     : "Schedule appointment"
//                 }
//                 link="/contacts"
//               />
//             </div>
//             <div className={cs.rightcol}>
//               <span className={cs.exp}>Our expertise:</span>
//               <p>
//                 Analysis of tax risks;
//                 <br />
//                 <br />
//                 Tax structuring of internal and international transactions;
//                 <br />
//                 <br />
//                 Performing of business restructuring;
//                 <br />
//                 <br />
//                 Establishment of national and international holding and other
//                 financial institutions;
//                 <br />
//                 <br />
//                 Support of clients during tax audits;
//                 <br />
//                 <br />
//                 Support of clients in judicial settlement with the tax
//                 authorities;
//                 <br />
//                 <br />
//                 Representation of the clients’ interests in courts.
//                 <br />
//                 <br />
//                 International tax planning: incorporation of Companies in
//                 overseas jurisdictions for the purposes of tax optimization;
//               </p>
//             </div>
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// const Content4_2 = (props) => {
//   return (
//     <div className={`${cs.content} ${cs.withBG}`}>
//       {props.lang === Langs.RU ? (
//         <div className={cs.contentSingle}>
//           <h1>Разрешение споров в сфере государственных закупок </h1>
//           <p>
//             Самыми сложными в данной практике являются споры о включении в
//             реестр недобросовестных участников государственных закупок. Наши
//             специалисты понимают, что делать в таких случаях и как избежать
//             включения компании в реестр.
//             <br />
//             <br />
//             Мы помогли десяткам клиентов избежать включения в реестр в самых
//             неоднозначных и непростых ситуациях.
//             <br />
//             <br />
//             Мы представляем поддержку на всех стадиях существования таких
//             споров, от момента составления претензии до производства по
//             пересмотру решений в суде апелляционной инстанции.
//           </p>
//           <ArrowButton label="Назначить встречу" link="/contacts" />
//         </div>
//       ) : (
//         <div className={cs.contentSingle}>
//           <h1>State Procurements Disputes Resolution </h1>
//           <p>
//             The most complicated are disputes on enlistment of Company in the
//             register of unscrupulous participants of state procurement. Our
//             specialists fully realized the way and methods of precluding Company
//             in joining this register.
//             <br />
//             <br />
//             We have successfully assisted to plenty of Clients in evading
//             enrolling into this register.
//             <br />
//             <br />
//             We are providing support to our Clients throughout the whole process
//             of dispute resolution.
//           </p>
//           <ArrowButton label="Schedule appointment" link="/contacts" />
//         </div>
//       )}
//     </div>
//   );
// };

// const Content4_3 = (props) => {
//   return (
//     <div className={`${cs.content} ${cs.withBG}`}>
//       <div className={cs.contentDouble}>
//         {props.lang === Langs.RU ? (
//           <h1>Практика разрешения споров</h1>
//         ) : (
//           <h1>Dispute resolution</h1>
//         )}

//         {props.lang === Langs.RU ? (
//           <div className={cs.leftcol}>
//             <p>
//               Практика разрешения споров является одной из ключевых практик
//               компании Volkov Group.
//               <br />
//               <br />
//               Среди наших клиентов представители среднего и крупного бизнеса,
//               находящегося как на территории Республики Казахстан, так и в
//               других странах. Наша команда успешно представляет интересы
//               клиентов в национальной и международных юрисдикциях по широкому
//               кругу споров, включая прецедентные споры, повлекшие изменение
//               правоприменительной практики.
//             </p>
//             <ArrowButton label="Назначить встречу" link="/contacts" />
//           </div>
//         ) : (
//           <div className={cs.leftcol}>
//             <p>
//               Dispute resolution practice considered as one of the key practices
//               of Volkov Group LLP.
//               <br />
//               <br />
//               Among our clients are representatives of medium and large
//               businesses incorporated in Kazakhstan and other countries as well.
//               Our team successfully represent clients in national and
//               international jurisdictions concerning the wide variety of
//               disputes, including landmark disputes which entail amendment of
//               the law enforcement practice.
//             </p>
//             <ArrowButton label="Schedule appointment" link="/contacts" />
//           </div>
//         )}

//         {props.lang === Langs.RU ? (
//           <div className={cs.rightcol}>
//             <span className={cs.exp}>Наши экспертизы:</span>
//             <br />
//             <p>
//               <strong>Сопровождение процедуры банкротства:</strong> <br />
//               Наша команда сформировала обширную правоприменительную практику
//               сопровождения процедуры банкротства, выработала большой массив мер
//               по противодействию недобросовестным должникам и защите интересов
//               участников процедуры банкротства. В рамках реализации проектов
//               нами успешно выполняется большое количество задач, в которые
//               входят, в частности, установление конечных бенефициаров сделок,
//               совершенных должником до и в период проведения процедуры
//               банкротства, направленных на выведение активов и денежных средств;
//               судебное оспаривание сделок должника; привлечение менеджмента и
//               сотрудников должника к ответственности, а также выполнение иных
//               задач.
//             </p>
//             <br />

//             <p>
//               <strong>Защита личных нематериальных благ:</strong>
//               <br />
//               <br />
//               Защита нематериальных благ является крайне деликатным и
//               чувствительным для клиента вопросом. Мы оказываем нашим клиентам
//               весь спектр услуг по комплексному сопровождению на досудебной
//               стадии, в ходе судебных разбирательств, а также после рассмотрения
//               дела в суде. Мы активно сотрудничаем с представителями СМИ и PR-
//               агентств, что позволяет нам более успешно защищать интересы
//               клиентов.
//             </p>
//             <br />

//             <p>
//               <strong>
//                 Представление и защита интересов клиентов в международных
//                 арбитражах, судебных органах:
//               </strong>
//               <br />
//               <br />
//               Имеющийся у нас значительный опыт арбитражного и судебного
//               представительства интересов клиентов на территории Республики
//               Казахстан, стран – участниц СНГ, а также международных юрисдикций
//               позволяет нам избирать верную тактику поведения, обеспечивающую
//               нашим клиентам комплексную юридическую защиту. В активе нашей
//               команды имеются прецедентные споры, которые повлекли изменения в
//               правоприменительной практике. Мы гордимся успешным
//               представительством интересов клиентов – лидеров национального и
//               международного бизнеса.
//             </p>
//           </div>
//         ) : (
//           <div className={cs.rightcol}>
//             <span className={cs.exp}>Our Expertise:</span>
//             <br />
//             <p>
//               <strong>Conducting of bankruptcy procedure:</strong> <br />
//               Our team has built up an extensive legal practice to support the
//               bankruptcy procedure, has developed a number of measures to
//               counter unscrupulous debtors and protect interests of the
//               bankruptcy procedure participants. We have successfully executed a
//               large number of tasks including finding the ultimate beneficiaries
//               of transactions conducted by the debtor before and during the
//               bankruptcy procedure aimed at removal of assetsand funds; Judicial
//               contestation of the transactions perpetrated by the Debtor; hold
//               accountable persons to the responsibility, such as board of
//               management and employees of the debtor, as well as execution of
//               other tasks.
//             </p>
//             <br />

//             <p>
//               <strong>Protection of intangible rights:</strong>
//               <br />
//               <br />
//               Protection of the intangible rights is extremely delicate and
//               sensitive issue to the Clients. We provide our clients with a full
//               range of services on comprehensive support at the pre-trial stage,
//               during trials and after the proceedings in court. We are actively
//               cooperating with media representatives and PR-agencies allowing us
//               to more effectively protect the interests of the clients.
//             </p>
//             <br />

//             <p>
//               <strong>
//                 Representing Clients in international arbitrage and judicial
//                 bodies:
//               </strong>
//               <br />
//               <br />
//               Our considerable experience of arbitration and legal
//               representation of the clients’ interests within the Republic of
//               Kazakhstan (hereinafter – RoK) and the CIS as well as
//               international jurisdictions allows us to select correct tactics
//               ensuring comprehensive legal protection of our clients. We have
//               represented our clients in landmark disputes which caused a change
//               in the law enforcement practice.
//             </p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// const Content4_4 = (props) => {
//   return (
//     <div className={`${cs.content} ${cs.withBG}`}>
//       {props.lang === Langs.RU ? (
//         <div className={cs.contentDouble}>
//           <h1>Практика финансового оздоровления деятельности компании</h1>

//           <div className={cs.leftcol}>
//             <p>
//               Команда специалистов данной практики специализируется на оказании
//               комплекса услуг по финансовому оздоровлению деятельности компании
//               посредством анализа ключевых показателей деятельности компании и
//               устранения имеющихся ошибок.
//               <br />
//               <br />
//               Команда наших специалистов проводит анализ договорной
//               документации, порядка ведения бухгалтерского, налогового и
//               управленческого учета, осуществляет налоговое планирование и
//               реструктуризацию, оптимизирует бизнес-процессы.
//             </p>
//             <ArrowButton
//               label={
//                 props.lang === Langs.RU
//                   ? "Назначить встречу"
//                   : "Schedule appointment"
//               }
//               link="/contacts"
//             />
//           </div>

//           <div className={cs.rightcol}>
//             <span className={cs.exp}>Наши экспертизы:</span>
//             <p>
//               Анализ текущего финансового состояния компании; Выдвижение на
//               основе анализа предложений по финансовому оздоровлению;
//               <br />
//               <br />
//               Ревизия заключенных гражданско-правовых сделок;
//               <br />
//               <br />
//               Выработка для компании механизмов налогового планирования;
//               <br />
//               <br />
//               Оптимизация иных процессов компании.
//             </p>
//           </div>
//         </div>
//       ) : (
//         <div className={cs.contentDouble}>
//           <h1>Financial refinement of Company activities</h1>

//           <div className={cs.leftcol}>
//             <p>
//               Our team specializes on rendering services of financial
//               improvement through the analysis of key performance indicators of
//               the company and elimination of existing errors.
//               <br />
//               <br />
//               Our team conducts analysis of contract documentation, accounting,
//               tax returns, provides tax planning and restructuring to optimize
//               business processes.
//             </p>
//             <ArrowButton label="Schedule appointment" link="/contacts" />
//           </div>

//           <div className={cs.rightcol}>
//             <span className={cs.exp}>Our Expertise:</span>
//             <p>
//               Analysis of the current financial condition of the company;
//               <br />
//               <br />
//               Analysis the possible manners for financial recovery;
//               <br />
//               <br />
//               Revision and verifying of concluded civil transactions;
//               <br />
//               <br />
//               Development of the company’s tax planning mechanism;
//               <br />
//               <br />
//               Optimization of other processes of the company.
//             </p>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const Content5_1 = (props) => {
//   return (
//     <div className={cs.content}>
//       <div className={cs.contentDouble} style={{ paddingBottom: "0" }}>
//         {props.lang === Langs.RU ? (
//           <div className={cs.leftcol}>
//             <h1>ПРОЕКТНОЕ ФИНАНСИРОВАНИЕ</h1>
//             <p>
//               Сопровождение и презентация проекта для целей участия в
//               инвестиционной программе МФЦА.
//             </p>
//           </div>
//         ) : (
//           <div className={cs.leftcol}>
//             <h1>PROJECT FINANCING</h1>
//             <p>
//               Accompanying and presentation of the project for participation in
//               investment program hosted by AIFC
//             </p>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// const Content5_2 = (props) => {
//   return (
//     <div className={`${cs.content} ${cs.withBG}`}>
//       {props.lang === Langs.RU ? (
//         <div className={cs.contentDouble}>
//           <h1>
//             Категория А: Инвестиционные проекты до 13,3 миллионов долларов США
//           </h1>
//           <div className={cs.leftcol}>
//             <span className={cs.exp}>
//               Основные преимущества, предоставляемые Участникам Инвестиционной
//               программы:
//             </span>
//             <ul>
//               <li>
//                 <p>
//                   Деятельность компании будет осуществляться в соответствии с
//                   общим правом Англии на территории Международного Финансового
//                   Центра Астана.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Доверие со стороны иностранных инвесторов за счет
//                   функционирования компании в соответствии с английским правом,
//                   собственных органов управления.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Наличие собственного международного суда и арбитражного центра
//                   для разрешения споров.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Получение преференцированного налогового режима для целей
//                   инвестирования в проекты, расположенные на территории
//                   Республики Казахстан. (КПН – 0%, НДС – 0%).
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Освобождение от таможенных пошлин при импорте технологического
//                   оборудования, комплектующих и запчастей к нему, сырья и (или)
//                   материалов.
//                 </p>
//               </li>

//               <li>
//                 <p>Освобождение от НДС на импорт сырья и (или) материалов.</p>
//               </li>

//               <li>
//                 <p>Государственные натурные гранты.</p>
//               </li>
//             </ul>
//           </div>
//           <div className={cs.rightcol}>
//             <span className={cs.exp}>Условия участия в программе:</span>
//             <p>
//               Суммарная стоимость проекта (NPV) не превышает 13,3. миллионов
//               долларов США.
//             </p>
//             <p>Регистрация на территории МФЦА компании и получение лицензии.</p>
//             <ArrowButton label="Назначить встречу" link="/contacts" />
//           </div>
//         </div>
//       ) : (
//         <div className={cs.contentDouble}>
//           <h1>
//             A Category: Investment projects with NPV limited to USD 13,3 million
//           </h1>
//           <div className={cs.leftcol}>
//             <span className={cs.exp}>
//               Principal advantages provided to the participants of investment
//               program
//             </span>
//             <ul>
//               <li>
//                 <p>
//                   Company’s activities will be governed by the English common
//                   law adopted in International Financial Centre Astana.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Confidence and safety for international investors by virtue of
//                   exploiting English common law and possessing own governing
//                   bodies.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Distinct judiciary bodies for dispute resolutions commanded by
//                   system of English common law.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Preferential tax regime for investment activities (CIT – 0 %
//                   and VAT – 0 %).
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Exemption from custom duties levied during import of
//                   technological equipment, raw materials, and spare parts.
//                 </p>
//               </li>

//               <li>
//                 <p>Exemption from VAT levied from export of raw materials.</p>
//               </li>
//             </ul>
//           </div>
//           <div className={cs.rightcol}>
//             <span className={cs.exp}>Conditions for Participants:</span>
//             <p>
//               Net Present Value of the project does not exceed USD 13.3 million.
//             </p>
//             <p>
//               Registration of a Company in Astana International Financial
//               Centre.
//             </p>
//             <ArrowButton label="Schedule appointment" link="/contacts" />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const Content5_3 = (props) => {
//   return (
//     <div className={`${cs.content} ${cs.withBG}`}>
//       {props.lang === Langs.RU ? (
//         <div className={cs.contentDouble}>
//           <h1>
//             Категория В: Инвестиционно-приоритетные проекты от 13,3 миллионов
//             долларов США по созданию новых производств; от 33,3 миллионов
//             долларов США по расширению действующих производств.
//           </h1>
//           <div className={cs.leftcol}>
//             <span className={cs.exp}>
//               Основные преимущества, предоставляемые Участникам Инвестиционной
//               программы:
//             </span>
//             <ul>
//               <li>
//                 <p>
//                   Регистрация на территории Международного Финансового Центра
//                   Астана и работа в соответствии с общим правом Англии.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Доверие со стороны иностранных инвесторов за счет регистрации
//                   и деятельности в Международном Финансовом Центре Астана.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Наличие собственного международного суда и арбитражного центра
//                   для разрешения споров.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Получение преференцированного налогового режима для целей
//                   инвестирования в проекты, расположенные на территории
//                   Республики Казахстан. (КПН – 0%, НДС – 0%).
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Освобождение от таможенных пошлин при импорте технологического
//                   оборудования, комплектующих и запчастей к нему, сырья и (или)
//                   материалов.
//                 </p>
//               </li>

//               <li>
//                 <p>Освобождение от НДС на импорт.</p>
//               </li>

//               <li>
//                 <p>Государственные натурные гранты.</p>
//               </li>

//               <li>
//                 <p>Освобождение от КПН деятельности.</p>
//               </li>

//               <li>
//                 <p>Освобождение от земельного налога.</p>
//               </li>

//               <li>
//                 <p>Освобождение от налога на имущество.</p>
//               </li>

//               <li>
//                 <p>Освобождение от рабочих разрешений на иностранцев.</p>
//               </li>

//               <li>
//                 <p>
//                   Инвестиционная субсидия на сумму до 30 % от стоимости СМР и
//                   приобретения оборудования без учета НДС и акцизов.
//                 </p>
//               </li>
//             </ul>
//           </div>
//           <div className={cs.rightcol}>
//             <span className={cs.exp}>Условия участия в программе:</span>
//             <p>
//               Суммарная стоимость проекта (NPV) начинается от 13,3 миллионов
//               долларов США для создания нового производства и от 33,3 миллионов
//               долларов США для расширения действующего производства.
//             </p>
//             <p>Регистрация на территории МФЦА компании и получение лицензии.</p>
//             <ArrowButton label="Назначить встречу" link="/contacts" />
//           </div>
//         </div>
//       ) : (
//         <div className={cs.contentDouble}>
//           <h1>
//             B Category: Investment projects with NPV exceeding USD 13,3 million
//             for newly registered enterprises; exceeding USD 33,3 million to
//             scale current operations
//           </h1>
//           <div className={cs.leftcol}>
//             <span className={cs.exp}>
//               Principal advantages provided to the participants of investment
//               program
//             </span>
//             <ul>
//               <li>
//                 <p>
//                   Company’s activities will be governed by the English common
//                   law adopted in International Financial Centre Astana.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Confidence and safety for international investors by virtue of
//                   exploiting English common law and possessing own governing
//                   bodies.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Distinct judiciary bodies for dispute resolutions commanded by
//                   system of English common law.
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Preferential tax regime for investment activities (CIT – 0 %
//                   and VAT – 0 %).
//                 </p>
//               </li>

//               <li>
//                 <p>
//                   Exemption from custom duties levied during import of
//                   technological equipment, raw materials, and spare parts.
//                 </p>
//               </li>

//               <li>
//                 <p>Exemption from VAT levied from export of raw materials. </p>
//               </li>

//               <li>
//                 <p>Exemption from CIT. </p>
//               </li>

//               <li>
//                 <p>Exemption from land tax </p>
//               </li>

//               <li>
//                 <p>Exemption from real estate tax. </p>
//               </li>

//               <li>
//                 <p>Exemption from work permissions for foreign employees. </p>
//               </li>
//             </ul>
//           </div>
//           <div className={cs.rightcol}>
//             <span className={cs.exp}>Conditions for Participants:</span>
//             <p>
//               Net Present Value of the project exceeds USD 13.3 million for
//               newly registered enterprises; exceeding USD 33,3 million to scale
//               current operations.
//             </p>
//             <p>
//               Registration of a Company in Astana International Financial
//               Centre.
//             </p>
//             <ArrowButton label="Назначить встречу" link="/contacts" />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

const Services = (props) => {
  return (
    <BrowserRouter>
      <div className={cs.wrapper}>
        <div className={cs.nav}>
          <div className={cs.leftpane}>
            <h1>{translate("Наши Сервисы", props.lang)}</h1>
            <ul>
              <li>
                <NavLink to="/services/1" activeClassName={cs.activeLink}>
                  <span>{translate("Регистрация в МФЦА", props.lang)}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to="/services/2" activeClassName={cs.activeLink}>
                  <span>{translate("Налоговая оптимизация", props.lang)}</span>
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/services/5" activeClassName={cs.activeLink}>
                  {props.lang === Langs.RU ? (
                    <span>ПРОЕКТНОЕ ФИНАНСИРОВАНИЕ</span>
                  ) : (
                    <span>PROJECT FINANCING</span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink to="/services/4" activeClassName={cs.activeLink}>
                  {props.lang === Langs.RU ? (
                    <span>юридический консалтинг</span>
                  ) : (
                    <span>Legal consulting</span>
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink to="/services/3" activeClassName={cs.activeLink}>
                  {props.lang === Langs.RU ? (
                    <span>Внедрение data driven подхода</span>
                  ) : (
                    <span>Introduction of data-driven approach</span>
                  )}
                </NavLink>
              </li> */}
            </ul>
          </div>
          <div className={cs.rightpane}>
            <Route
              exact
              path={["/services/", "/services/1"]}
              render={() => <LabelServices1 {...props} />}
            />
            <Route
              path="/services/2"
              render={() => <LabelServices2 {...props} />}
            />
            {/* <Route
              path="/services/3"
              render={() => <LabelServices3 {...props} />}
            />
            <Route
              path="/services/4"
              render={() => <LabelServices4 {...props} />}
            />
            <Route
              path="/services/5"
              render={() => <LabelServices5 {...props} />}
            /> */}
          </div>
        </div>

        <Route
          exact
          path={["/services/", "/services/1"]}
          render={() => <Content1 {...props} />}
        />
        <Route
          exact
          path="/services/2"
          render={() => <Content2 {...props} />}
        />
        {/* <Route
          exact
          path="/services/3"
          render={() => <Content3 {...props} />}
        />

        <Route
          exact
          path="/services/4"
          render={() => <Content4_1 {...props} />}
        />
        <Route
          exact
          path="/services/4"
          render={() => <Content4_2 {...props} />}
        />
        <Route
          exact
          path="/services/4"
          render={() => <Content4_3 {...props} />}
        />
        <Route
          exact
          path="/services/4"
          render={() => <Content4_4 {...props} />}
        />

        <Route
          exact
          path="/services/5"
          render={() => <Content5_1 {...props} />}
        />
        <Route
          exact
          path="/services/5"
          render={() => <Content5_2 {...props} />}
        />
        <Route
          exact
          path="/services/5"
          render={() => <Content5_3 {...props} />}
        /> */}
      </div>
    </BrowserRouter>
  );
};

export default Services;
