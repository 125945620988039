import React, { useState } from "react";
import Header from "./components/Header/Header.jsx";
import { BrowserRouter, Route } from "react-router-dom";
import MainPage from "./pages/MainPage/MainPage.jsx";
import Services from "./pages/Services/Services";
import AboutCompany from "./pages/AboutCompany/AboutCompany";
import Contacts from "./pages/Contacts/Contacts";
import Footer from "./components/Footer/Footer";
import "normalize.css";

import "./App.scss";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Langs } from "./store/lang.js";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [lang, setLang] = useState(Langs.RU);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <div className="App">
        <Header lang={lang} setLang={setLang} />

        <div className="content">
          <Route
            exact
            path={["/main", "/"]}
            render={() => <MainPage lang={lang} />}
          />
          <Route path="/services" render={() => <Services lang={lang} />} />
          <Route
            exact
            path="/about"
            render={() => <AboutCompany lang={lang} />}
          />
          <Route
            exact
            path="/contacts"
            render={() => <Contacts lang={lang} />}
          />
        </div>

        <Footer lang={lang} setLang={setLang} />
      </div>
    </BrowserRouter>
  );
}

export default App;
