import React from 'react'
import cs from './scss/Phone.module.scss'
import phone_icon from '../../assets/img/phone.svg'

const Phone = () => {
    return (
        <div className={cs.phone}>
            <a href="tel:+7 778 978 7999"><div className={cs.phoneiconwrapper}><img src={phone_icon} alt="" /></div>+7 778 978 7999</a>
        </div>
    )
} 
// старый номер +7 778 978 7999
// новый номер +7 777 811 7119
export default Phone;