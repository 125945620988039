import React from "react";
import cs from "./scss/Menu.module.scss";
import { NavLink } from "react-router-dom";
import { translate } from "../../langs";

const NavItem = (props) => {
  return (
    <div className={`${cs.nav_buttons__button}`}>
      <NavLink to={props.link} activeClassName={cs.activeLink}>
        <span>{props.content}</span>
      </NavLink>
    </div>
  );
};

const Menu = (props) => {
  return (
    <div className={cs.nav_buttons}>
      <NavItem link="/main" content={translate("Главное", props.lang)} />
      <NavItem link="/services/1" content={translate("Сервисы", props.lang)} />
      <NavItem link="/about" content={translate("О компании", props.lang)} />
      <NavItem link="/contacts" content={translate("Контакты", props.lang)} />
    </div>
  );
};

export default Menu;
