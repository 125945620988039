import React from 'react'
import button from '../../assets/img/button.svg'
import { NavLink } from 'react-router-dom'

import cs from './scss/ArrowButton.module.scss'


const ArrowButton = (props) => {
  return (
    <div className={cs.wrapper + ' ArrowButton_wrapper'}>
      <div className={cs.arrow_button}>
        <span>{props.label}</span> { props.nav ? <NavLink to={props.link}><img src={button} alt="" /></NavLink> : <a href={props.link}><img src={button} alt="" /></a>}
      </div>
    </div>
  )
}

export default ArrowButton;