import React from 'react'
import Phone from '../UI/Phone'
import Lang from '../UI/Lang'
import Menu from '../UI/Menu'
import MyDrawer from '../UI/MyDrawer'
import { NavLink } from 'react-router-dom'

import cs from './scss/Header.module.scss'

import logo from '../../assets/img/logo.svg'


const Header = (props) => {
	return (
		<div className={cs.header_wrapper}>
			<MyDrawer {...props} />
			<ul className={cs.header}>

				<li><NavLink to='/main'><div className={cs.logo_label}><img src={logo} alt="" /> <span>Volkov Group</span></div></NavLink></li>

				<li>
					<Menu {...props} />
				</li>

				<li>
					<Phone />
				</li>

				<li >
					<div className={cs.lang}> <Lang {...props} /></div>
				</li>
			</ul>
		</div>
	)
}

export default Header;