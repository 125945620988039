// import ru from "./ru.json";
import en from "./en.json";
import ch from "./ch.json";

export const Langs = {
  RU: "RU",
  EN: "EN",
  CH: "CH",
};

export const translate = (text, lang = Langs.RU) => {
  if (lang === Langs.EN) {
    return en[text] ?? text;
  } else if (lang === Langs.CH) {
    return ch[text] ?? text;
  }
  return text;
};
