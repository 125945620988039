import React from "react";
import { Carousel } from "react-responsive-carousel";
import "../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
import "./scss/Slider.scss";
import ArrowButton from "../../components/UI/ArrowButton";
import { translate } from "../../langs";

const Slider = (props) => {
  return (
    <div className="sliderWrapper">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        interval={5000}
        stopOnHover={true}
      >
        <div className="carousel_item item_1">
          <h1>
            <h1>{translate("Регистрация в МФЦА", props.lang)}</h1>
          </h1>
          <ArrowButton
            nav={true}
            label={translate("Подробнее", props.lang)}
            link="/services/1"
          />
        </div>
        <div className="carousel_item item_2">
          <h1>
            <h1>{translate("Налоговая оптимизация", props.lang)}</h1>
          </h1>
          <ArrowButton
            nav={true}
            label={translate("Подробнее", props.lang)}
            link="/services/2"
          />
        </div>
        <div className="carousel_item item_3">
          <h1>
            <h2>
              {translate(
                "Лондонская консалтинговая компания IFS Consultants стала партнером Volkov Group",
                props.lang
              )}
            </h2>
          </h1>
          <ArrowButton
            nav={true}
            label={translate("Подробнее", props.lang)}
            link="/about"
          />
        </div>
      </Carousel>
    </div>
  );
};

export default Slider;
