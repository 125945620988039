import React from "react";
import cs from "./scss/Contacts.module.scss";
import Feedback from "../../components/UI/Feedback";
import { translate } from "../../langs";

const Contacts = (props) => {
  return (
    <div className={cs.wrapper}>
      <div className={cs.block1}>
        <div className={cs.text}>
          <h1>{translate("Наши контакты", props.lang)}</h1>
          <ul>
            <li>{translate("г. Астана", props.lang)}</li>
            <li>{translate("Республика Казахстан", props.lang)}</li>
            <li>{translate("Резидент МФЦА", props.lang)}</li>
            <li>
              <span className={cs.email}>info@volkovgroup.kz</span>
            </li>
          </ul>
        </div>
      </div>
      <Feedback {...props} />
    </div>
  );
};

export default Contacts;
